import React from "react";
import "../../App.css";
import TextBox from '../TextBox/TextBox.js';


export default function ExerElement(props) {
	// const handleChangeForm = (val) => props.onChangeForm(props.id,val);
	var myval = props.value;
	var myname = props.exername;
	var mysel = props.selected;
	if(typeof props.value == 'undefined') myval = '';
	if(typeof props.exername == 'undefined') myname = '';
	if(typeof props.selected == 'undefined') mysel = false;
	return (
		<span className='smartname'>
			<span className='checkandname' onClick={() => props.onToggle(props.id)}>
				<svg viewBox="0 0 100 100" style={{height: 20, width: 20}} xmlns="http://www.w3.org/2000/svg" >
					<path
						stroke='aqua'
						strokeWidth={mysel? "10":"0"}
						fill="none"
						d='M16.667,62.167c3.109,5.55,7.217,10.591,10.926,15.75 c2.614,3.636,5.149,7.519,8.161,10.853c-0.046-0.051,1.959,2.414,2.692,2.343c0.895-0.088,6.958-8.511,6.014-7.3 c5.997-7.695,11.68-15.463,16.931-23.696c6.393-10.025,12.235-20.373,18.104-30.707C82.004,24.988,84.802,20.601,87,16'
						strokeDashoffset={0}
						style={{strokeLinecap: 'round', strokeLinejoin: 'round', transition: 'stroke-dashoffset 0.2s ease-in-out', strokeDasharray: '0 0'}}
					/>
				</svg>
				<span className='label' style={{color: mysel ? 'aqua':'white'}}>
					{myname}
				</span>
			</span>
			<TextBox
				id={props.id} 
				value={myval}
				cursorPos={props.cursorPos}
				setCursorPos={props.setCursorPos}
				statusColor={props.statusColor}
				setStatusColor={props.setStatusColor}
				onChange= {(val) => props.onChangeForm(props.id,val)}
			/>
		</span>
	);
}




