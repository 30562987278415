import React, {useRef, useState, useEffect} from 'react';
import "../../App.css";
 
export default function TextBox(props) {
    const textareaRef = useRef(); 
// to point to the input box child component, so that handleCursor()
// can access it

	const handleChange = (e) => {
		let myval = e.target.value;
		props.onChange(myval); // propagate the new text value up the parents stack, so we can update the value for this id in exerObj

	}

	const handleCursor = () => {
		let myval = props.value;
		const regex1 = /(?:^|\s)(\d+)(?=\s|$)/g;
		let match;
		let mytotal = 0;
		while ((match = regex1.exec(myval)) !== null) {
			mytotal += parseInt(match[1], 10); 
		}
// console.log('total='+mytotal)

		let newobj = JSON.parse(JSON.stringify(props.cursorPos));
		newobj[props.id]=textareaRef.current.selectionStart;
		props.setCursorPos(newobj);

		const regex2 = /\s(\d+)Total/g;
		match=regex2.exec(myval);
		if (match !== null){
			let oldpat = match[1]+'Total'
			let newpat = mytotal+'Total'
			myval = myval.replace(oldpat,newpat)
		}
		else {
			myval = myval+' '+mytotal+'Total'
		}
		props.onChange(myval);

		newobj = JSON.parse(JSON.stringify(props.statusColor));
                let mylist = Object.entries(newobj);
                for(let entry of mylist){
                    newobj[entry[0]] = 'aqua';
                }
                newobj[props.id] = 'red';
		props.setStatusColor(newobj);

	}
		
	const textStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'fontSize': '20px',
		width: `${props.value.length}ch`,
		minWidth: '100%'
	};
	let mycursorPos = props.cursorPos[props.id];
        
	const barStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'fontSize': '20px',
		height: '1px',
		outline:'none',
		backgroundColor: props.statusColor[props.id],
		width: `${Math.max(0,props.value.substring(0,mycursorPos).length-1)}ch`, // Set the width based on the number of characters
	};
	
/*
	useEffect(() => {
		// console.log('useffect: cursor='+cursorPos+
		// 	' text='+val.substring(0,cursorPos));
	}, [mycursorPos,val]);
*/

	return (
		<div style={{
			display:'flex',
			flexDirection:'column',
			width:'55%',
		}}>
			<input
				style={textStyle}
				value={props.value} 
				ref={textareaRef}
				onChange={(e)=>handleChange(e)} 
				onBlur={handleCursor}
			/>
			<input style={barStyle}/>
		</div>

	);
	
}




