import React, { useState, useEffect } from "react";
import "./SubmitButton.css";


export default function SubmitButton(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
) {
	const tc = 'white'
	const [textcolor, settextcolor] = useState(tc);
	const [stopTime, setStopTime] = useState(0);
	const [x, setX] = useState(0);
/*
	useEffect( ()=>{
		if(textcolor!==tc){
			let tt = new Date();
console.log('submitbutton useeffect, stopTime=',stopTime,' tt=',tt);
			if(tt>stopTime)
				settextcolor(tc);
		}
	});
*/
	useEffect( ()=>{
	    let interval = setInterval(() => {
		if(textcolor!==tc){
			let tt = new Date();
			if(tt>stopTime)
				settextcolor(tc);
		}
	    setX(x+1);
	    }, 50);
	    return (() => {
		    clearInterval(interval);
	    });
          });

/*
	useEffect( ()=>{
		if(textcolor!==tc){
			const mytimer = setInterval(() => {
				settextcolor(tc);
			}, 1000);	
			// clearing interval
			return () => clearInterval(mytimer);	
		}
	});
*/

	const handleSubmit = () => {
		settextcolor('orange'); // change color, and in 1 second revert color
		let tt = new Date();
		tt.setSeconds(tt.getSeconds() + 1)
		setStopTime(tt);
		const d = new Date();
		let timestamp = d.toLocaleString()+' ';
		let newtext = timestamp+':  ';
		for (const [key, entry] of Object.entries(exerObj)) {
		  if(entry.selected){
			newtext += entry.name;
			if(entry.value){
			  newtext += ' ' + entry.value +', ';
			}
			else {
			  newtext += ', ';
			}
		  }
		}
		newtext = newtext.slice(0,-2);
		newtext += '\r\n';
		let tlog = textvalue.slice();
		tlog+=newtext;
		setTextvalue(tlog);
		var messageBody = document.querySelector('#logboxid');
		if(messageBody!=null){
		  messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
		}
		writestate(exerObj, tlog);
	  }

	return (
		<button className='submitbutton'
			style={{color:textcolor, 'fontSize': textcolor===tc ? '20px' : '40px'}}
			onClick={handleSubmit} >
			+
		</button>
	);
}

